<template>
  <div class="beian">
    <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44060502002532" style="
        display: inline-block;
        text-decoration: none;
        height: 20px;
        line-height: 20px;
      ">
      <span style="
          float: left;
          height: 20px;
          line-height: 20px;
          margin: 0px 10px 0px 5px;
          color: #939393;
        ">备案号：赣ICP备2021000715号-1</span>
      <img src="@/assets/images/beian.png" style="float: left" />
      <p style="
          float: left;
          height: 20px;
          line-height: 20px;
          margin: 0px 0px 0px 5px;
          color: #939393;
        ">
        粤公网安备 44060502002532号
      </p>
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style>
.beian {
  width: 100%;
  padding: 10px 0;
  background: #1d1d21;
  text-align: center;
  font-size: 16px;
}
</style>